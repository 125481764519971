import Contact from './Contact';
import Riddle from './Riddle';
import SideBar from './SideBar';
import { useEffect } from 'react';


const HomePage = () => {

  const startDay = new Date("2023-10-01T09:30:00.000+01:00");
  const today = new Date();
  const day = Math.floor((today.getTime() - startDay.getTime()) / (1000 * 3600 * 24));

  useEffect(() => {
    const reloadHour = 8;
    const reloadMinute = 30;
    const reloadSecond = 0;
    const timezoneOffset = today.getTimezoneOffset()/60;

    const reloadTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), reloadHour - timezoneOffset, reloadMinute, reloadSecond);

    if (today > reloadTime) {
      reloadTime.setDate(reloadTime.getDate() + 1);
    }

    const timeUntilReload = reloadTime.getTime() - today.getTime();

    setTimeout(() => {
      window.location.reload();
    }, timeUntilReload);
  }, []);

  const dateProps = {
    day: day,
    startDay: startDay
  }

  return(
    <div className="App">
      <h1 className="Header"> 1 jour 1 énigme: tentez de résoudre l'énigme du jour!</h1>
      <h1 className="Header">1jour1enigme: tous les jours une nouvelle enigme, devinette, casse-tête, charade, rébus ou mystère.</h1>
      <h2 className="Header">1jour1enigme: tous les jours une nouvelle enigme, devinette, casse-tête, charade, rébus ou mystère.</h2>
      <h2 className="Header"> 1 jour 1 énigme: tentez de résoudre l'énigme du jour!</h2>
      <SideBar dateProps={dateProps}/>
      <Riddle dateProps={dateProps}/>
      <Contact/>
    </div>
  );
}
export default HomePage;